<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Add Contact Type</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="contactTypeName">Name <span class="red-dot ml-3"></span></label>
        <input id="contactTypeName" name="contactTypeName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('contactTypeName') }"
          autocomplete="off" placeholder="Name" v-model="contactType.name" v-validate="'required'" data-vv-as="Name" />
        <div class="invalid-feedback">The Name field is required.</div>
      </div>

      <div class="form-group">
        <label for="contactTypeSortOrder">Sort Order <span class="red-dot ml-3"></span></label>
        <input id="contactTypeSortOrder" name="contactTypeSortOrder" type="text" class="form-control" :class="{ 'is-invalid': errors.has('contactTypeSortOrder') }"
          autocomplete="off" placeholder="Sort Order" v-model="contactType.sortOrder" v-validate="'required'" data-vv-as="Sort Order" />
        <div class="invalid-feedback">The Sort Order field is required.</div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success">Save</button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'contact-type',
  data: function () {
    return {
      isEdit: false,
      contactType: {}
    }
  },
  computed: {
    ...mapGetters(["getContactType"])
  },
  mounted: function() {
    this.contactType = { ...this.getContactType };
    this.isEdit = !!this.contactType.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.saveContactType({ data: this.contactType, isEdit: this.isEdit })
        .then(() => {
          Toast.success(this, "Successfully saved!");
          this.$bvModal.hide("bv-modal-contact-type");
          this.closeModal();
        })
        .catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-contact-type");
    },
    ...mapActions(["saveContactType"])
  }
}
</script>
